.sidebar {
  display: flex;
  position: relative;
  border-right: 1px solid #e9e5e5;
  overflow: visible;

  .closeIcon {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1000;
  }

  .listItemBtn {
    min-height: 48;
    padding: 2.5;

    &:hover {
      background: rgb(200 196 196 / 20%);
    }
  }

  .active {
    background: rgb(200 196 196 / 40%);
  }
}
