.drawer {
  .drawerPaper {
    display: flex;
    flex-direction: column;

    .drawerHeader {
      display: flex;
      justify-content: flex-end;

      .label {
        font-size: 25px;
      }

      .closeIcon {
        color: white;
        width: 30;
        height: 30;
      }
    }

    .drawerContent {
      width: 400px;
      padding: 15px;

      .select {
        margin-top: 20px;
        width: 100%;

        // div {
        //   color: white;
        // }

        // fieldset {
        //   border: 1px solid white !important;
        //   background-color: transparent;
        // }

        // svg {
        //   color: white;
        // }
      }

      .input {
        margin-top: 20px;
        width: 100%;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;

      .saveBtn {
        color: rgb(17, 243, 17);
        font-size: 18px;
        font-weight: 600;
      }

      .cancelBtn {
        color: red;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}
