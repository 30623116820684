.content {
  padding: 0px 15px 15px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text {
    font-size: 30px;
  }

  .sliderContainer {
    .select {
      width: 100%;
      margin-top: 20px;
    }
  }

  .textContainer {
    gap: 15px;
    display: flex;
    justify-content: space-between;

    .statusContainer {
      display: flex;
      gap: 5px;
      align-items: center;

      .label {
        font-size: 25px;
        color: black;
      }

      .status {
        font-size: 25px;
      }
    }
  }
}
