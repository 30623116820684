.requestInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .select {
    width: 100%;
  }

  .filters {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 20px;

    .filter {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 10px;
      border: 1px solid white;
      border-radius: 5px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .closeIcon {
          color: red;
        }
      }

      .operations {
        width: 100px;
      }
    }
  }

  .watchTitle {
    margin-bottom: 10px;
  }

  .newValueInp {
    width: 100%;
    margin-top: 15px;
  }
}
