.modal {
  width: 100%;
  height: 100%;

  .modalContent {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 700px;
    height: auto;
    padding: 20px;
    border-radius: 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .content {
      width: 100%;
      padding: 15px;

      .fieldContainer {
        margin-top: 15px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .field {
          width: 300px;
        }
      }
    }

    .actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;

      .cancelBtn {
        margin-left: 10px;
        font-weight: 600;
        color: rgb(136, 136, 136);
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .modal {
    .modalContent {
      width: 80%;

      .header {
        .title {
          font-size: 18px;
        }
      }
    }
  }
}
