.container {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4a5568;
}

.inputWrapper {
  display: flex;
  align-items: center;
}

.input {
  display: none;
}

.button {
  padding: 0.5rem 1rem;
  background-color: #5a67d8;
  color: #ffffff;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #434190;
  }
}

.fileName {
  margin-left: 1rem;
  color: #4a5568;
  font-size: 0.875rem;
}

.error {
  color: #e53e3e;
  margin-top: 0.25rem;
  font-size: 0.75rem;
}
