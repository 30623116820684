.node {
  position: relative;
  width: 350px;
  border: 1px solid black;
  border-radius: 10px;
  border-top-right-radius: 0px;
  padding: 25px;

  .content {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      .text {
        font-size: 30px;
        font-weight: 600;
        text-wrap: nowrap;
        color: #ff7800 !important;
      }
    }
  }
}
