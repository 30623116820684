.nodeIcons {
  width: 100%;

  .animatedIcon {
    animation: roundAndSpin 4s linear infinite;

    @keyframes roundAndSpin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
