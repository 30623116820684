.mapWrapper {
  width: 100%;
  position: relative;

}

.leafletContainer {
  height: 100vh;
}

.tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 0.5rem;
  pointer-events: none;
}

.spexPinPopup {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5000;
  width: 400px;
  height: 360px;
  background-color: white;
}

// Hide the white arrow Leaflet attaches to the corner of the tooltip by default:
.leafletTooltip {
  padding: 0;
  background-color: transparent;
  border-color: transparent;

  &::before {
    display: none;
  }

  p {
    margin-bottom: 0
  }
}
