.playbookPage {
  width: 100%;
  height: calc(100vh - 75px);
  display: flex;

  .horizontal {
    transition: transform 0.3s ease;
    transform: rotate(90deg);
  }

  .vertical {
    transition: transform 0.3s ease;
  }

  .chart {
    width: 100%;
    height: 100%;
  }

  .themeModeBtn {
    border: 1px solid black;
  }

  .pdfBtn {
    text-decoration: none;
    color: white;
    padding: 15px;
    border: 1px solid black;
  }

  .rightPanel {
    display: flex;
    padding: 0px;
    margin: 0px;
    align-items: center;
    margin-top: 10px;

    .buttons {
      display: flex;
      align-items: center;
      gap: 20px;
      overflow: hidden;
      transition: height 0.5s ease;

      .layoutBtn {
        text-wrap: nowrap;
      }
    }

    .backArrowBtn {
      transition: transform 0.3s ease;
      transform: rotate(180deg);
    }

    .arrowBtn {
      transition: transform 0.3s ease;
    }
  }
}
