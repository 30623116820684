.content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text {
    // width: 300px;
    font-size: 30px;
  }

  .textContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    .text {
      font-size: 30px;
    }
  }

  .inputs {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
