.error {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .errorContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid rgb(186, 186, 186);
    padding: 15px;

    .code {
      font-size: 40px;
    }

    .border {
      font-size: 40px;
    }

    .message {
    }
  }
}
