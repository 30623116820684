.locationSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;

  .content {
    display: flex;
    gap: 15px;
    height: 100%;

    .leftContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .addBtn {
        width: 100%;
      }

      .mapContainer {
        flex: 1;
      }

      .detailedContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
        padding: 0px 15px 15px;
        overflow-y: scroll;

        .item {
          gap: 10px;
          padding: 15px 20px 15px 15px;
          display: flex;
          flex-direction: column;
          border: 1px solid rgb(4, 17, 26);
          border-radius: 5px;
          width: 100%;
          position: relative;
          cursor: pointer;

          .details {
            display: flex;
            gap: 10px;

            .info {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 250px;
            }
          }

          .circle {
            right: 10px;
            top: 10px;
            position: absolute;
          }
        }

        .lightItem {
          gap: 10px;
          padding: 15px 20px 15px 15px;

          display: flex;
          flex-direction: column;
          border: 1px solid rgb(189, 189, 189);
          border-radius: 5px;
          width: 100%;
          position: relative;
          cursor: pointer;

          .details {
            display: flex;
            gap: 10px;

            .info {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 200px;
            }
          }

          .circle {
            right: 10px;
            top: 10px;
            position: absolute;
          }
        }

        .selectedItem {
          gap: 10px;
          padding: 15px 20px 15px 15px;
          display: flex;
          flex-direction: column;
          border: 1px solid white;
          border-radius: 5px;
          width: 100%;
          position: relative;
          cursor: pointer;

          .details {
            display: flex;
            gap: 10px;

            .info {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 200px;
            }
          }

          .circle {
            right: 10px;
            top: 10px;
            position: absolute;
          }
        }
      }
    }

    .rightContainer {
      flex: 2;
      height: 100%;

      .nextBtnContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .nextBtn {
          margin-top: 10px;
          width: 250px;
        }
      }

      .sectionContainer {
        border: 1px solid gray;
        border-radius: 5px;
        width: 100%;

        .titleContainer {
          display: flex;
          width: 100%;
          padding: 15px;
          border: 1px solid gray;
          justify-content: space-between;
          align-items: center;

          .title {
            font-size: 18px;
            font-weight: 600;
          }

          .item {
            display: flex;
            gap: 10px;
            align-items: center;

            .field {
              width: 200px;
            }
          }

          .field {
            width: 250px;
          }

          .actions {
            gap: 10px;
            display: flex;
          }
        }

        .section {
          display: flex;
          flex-direction: column;
          padding: 10px;
          width: 100%;

          .titleContainer {
            display: flex;
            width: 100%;
            padding: 15px;
            border: 1px solid gray;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;

            .title {
              font-size: 18px;
              font-weight: 600;
            }

            .saturn {
              display: flex;
              align-items: center;
            }
          }

          .addFreq {
            width: 300px;
            margin-top: 20px;
          }

          .subSection {
            border: 1px solid gray;
            border-top: 0px;
            padding: 25px 15px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .singleFrequency {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .field {
                width: 180px;
              }

              .label {
                width: 120px;
              }

              .details {
                display: flex;
                align-items: center;
                gap: 10px;

                .field {
                  width: 80px;
                }
              }
            }

            .addFreq {
              width: 300px;
              margin-top: 20px;
            }

            .fieldContainer {
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;

              .locationContainer {
                display: flex;
                gap: 10px;

                .details {
                  display: flex;
                  flex-direction: column;
                  gap: 10px;

                  .locationField {
                    width: 110px;
                  }
                }
              }
            }

            .label {
              font-size: 15px;
              display: flex;

              .asterisk {
                color: red;
              }
            }

            .field {
              width: 350px;
            }
          }

          .detailsSubSection {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 10px 0px;

            .fieldContainer {
              display: flex;
              width: 100%;
              justify-content: space-between;
              align-items: center;

              .locationContainer {
                display: flex;
                gap: 10px;

                .details {
                  display: flex;
                  flex-direction: column;
                  gap: 10px;

                  .locationField {
                    width: 110px;
                  }
                }
              }
            }

            .label {
              font-size: 15px;
              display: flex;

              .asterisk {
                color: red;
              }
            }

            .field {
              width: 350px;
            }
          }
        }
      }
    }
  }
}
