.spectrumManager {
  width: 100%;
  flex: 1;
  display: flex;
  position: relative;

  .content {
    width: 100%;
    display: flex;
    padding: 15px 25px;
  }
}
