.sidebar {
  border-right: 1px solid gray;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  overflow-y: scroll;
  overflow-x: unset;
  transition: width 1s ease;

  .content {
    position: sticky;
    z-index: 999;
    left: -1px;
    top: 0px;
    transition: width 2s ease;

    .btn {
      position: absolute;
      width: 40px;
      height: 40px;
      left: 5px;
      top: 3px;
      background-color: #13222e;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid;
      z-index: 9999;
      transition: transform 1s ease;
    }
  }

  .title {
    font-size: 25px;
    margin-bottom: 50px;
  }

  .events {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 40px;
    overflow: hidden;
    transition: width 1s ease;
  }
}

.sidebar::-webkit-scrollbar {
  display: none;
}
