.node {
  position: relative;
  border: 1px solid black;
  border-radius: 10px;
  border-top-right-radius: 0px;
  background-color: rgb(255, 166, 0);
  padding: 15px;

  .content {
    min-width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .text {
      font-size: 30px;
      text-wrap: nowrap;
      color: #ff7800 !important;
      font-weight: 600;
      font-size: 30;
      font-weight: 600;
    }

    .buttonsContainer {
      display: flex;
      gap: 20px;
      width: 100%;
      justify-content: center;

      .button {
        font-size: 25px;
        border: 1px solid;
      }
    }
  }
}
