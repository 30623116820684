.search {
  width: 100%;
  margin: 0px 0px 40px !important;
}

.dragNDrop {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
