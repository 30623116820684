.modal {
  width: 100%;
  height: 100%;

  .modalContent {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 500px;
    height: auto;
    padding: 20px;
    border-radius: 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 25px;
        font-weight: 600;
      }
    }

    .selects {
      width: 100%;
      display: flex;
      margin-top: 30px;
      justify-content: center;

      .fieldContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .field {
          width: 300px;
        }
      }
    }

    .actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
      gap: 10px;
    }
  }
}
