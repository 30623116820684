.sidebar {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 2000;
  height: 100vh;
}

.container {
  position: relative;
  height: 100%;
}

.box {
  padding: 10px 20px;
  overflow: hidden;
  transition: width 0.5s, padding 0.5s;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 400px;
  height: 100%;

  .label {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &.closed {
    width: 0;
    padding: 10px 0;
  }
}

.arrowButton {
  position: absolute;
  top: 50%;
  right: -44px;
  z-index: 1;
  transform: translateY(-50%) rotate(-180deg);
  transition: transform 0.5s;

  &.closed {
    transform: translateY(-50%) rotate(0);
  }

  > div {
    padding: 2px;
    cursor: pointer;

    &:hover {
      transition: transform 0.3s;
      transform: scale(1.1);
    }
  }
}

.heatmapButton {
  position: absolute;
  top: 8px;
  width: max-content;
  transition: left 0.5s;
  display: flex;
  gap: 8px;

  > button {
    color: rgb(22, 119, 56);
  }
}

.filter {
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 6px 0;

  input,
  select {
    border: 1px solid #ccc;
    outline: none;
    padding: 2px 4px;
    min-width: 100px;
  }
}

.frequencies {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.frequencySlider {
  padding: 0 12px;
}

.customFilters {
  overflow-y: auto;
  max-height: 200px;
}
