.postRequestInfo {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 25px;
  }

  .urlContainer {
    display: flex;
    gap: 15px;
    align-items: center;
    margin: 20px 0px;

    .select {
      flex: 3;
    }

    .slash {
      font-size: 40px;
    }

    .input {
      flex: 2;
    }
  }

  .bodyHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .headers {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0px 20px;

    .header {
      display: flex;
      gap: 15px;
      align-items: center;
    }

    .btnContainer {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }

  .aceEditor {
    height: 300px !important;
    width: 400px !important;
    margin-top: 10px;
  }
}
