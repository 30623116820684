.node {
  position: relative;
  position: relative;
  border: 1px solid black;
  border-radius: 10px;
  border-top-right-radius: 0px;
  padding: 15px;
  background-color: green;

  .content {
    min-width: 300px;
    display: flex;
    align-items: center;

    .textContainer {
      width: 60%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text {
        font-size: 25px;
        font-weight: 700;
        color: white !important;
      }
    }
  }
}
