.login {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .spexLogo {
    width: 800px;
  }

  .btnContainer {
    margin-top: 100px;

    .auth0Logo {
      width: 30px;
      height: 30px;
    }

    .loginBtn {
      border: 1px solid gray;
      border-radius: 10px;
      display: flex;
      gap: 10px;
    }
  }
}
