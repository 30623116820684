.tableComponent {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid gray;
  border-radius: 2px;
  position: relative;

  .titleContainer {
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid gray;
    margin-bottom: 30px;

    .title {
      font-size: 25px;
      font-weight: 600;
    }

    .action {
      display: flex;
      gap: 10px;
    }
  }

  // td {
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   max-width: 150px;

  //   .coloredCell {
  //     border-radius: 20px;
  //     padding: 1px 2px;
  //     display: flex;
  //     justify-content: center;
  //     white-space: nowrap;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     max-width: 150px;

  //     .value {
  //       font-size: 14px;
  //     }
  //   }
  // }

  // th {
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   max-width: 150px;
  //   padding: 0px 10px;

  //   & > p {
  //     font-weight: 600;
  //   }
  // }

  .table {
    height: 100%;

    .headCell {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }

    .bodyCell {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;

      .coloredCell {
        border-radius: 20px;
        padding: 1px 2px;
        display: flex;
        justify-content: center;

        .value {
          font-size: 14px;
        }
      }
    }
  }
}
