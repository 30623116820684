.draggableBox {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  border: 1px solid black;
  background-color: #3c90da;
  border-bottom: 0px;
  z-index: 30;
  height: 30px;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  right: 0px;
  top: -30px;
}
