.topHandle {
  width: 15px !important;
  height: 15px !important;
  margin-top: -3px;
}

.bottomHandle {
  width: 15px !important;
  height: 15px !important;
  margin-bottom: -3px;
}
