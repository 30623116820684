.platforms {
  flex: 1;

  .tableContainer {
  }

  .titleContainer {
    .title {
      font-size: 25px;
      font-weight: 600;
    }
  }

  .tableContainer {
    .titleContainer {
      padding: 10px;
      border: 1px solid gray;
      margin-top: 30px;

      .title {
        font-size: 25px;
        font-weight: 600;
      }
    }

    .createPlatformContainer {
      width: 100%;
      display: flex;
      margin-top: 10px;
      justify-content: flex-end;
    }
  }
}
