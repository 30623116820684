.nodeItem {
  width: 100%;
  border-radius: 10px;
  cursor: move;
  padding: 20px 20px 20px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .iconContainer {
    display: flex;
    gap: 10px;

    .dragIcon {
      height: 30px;
    }

    .description {
      color: gray;
      font-size: 14px;
    }
  }
}
