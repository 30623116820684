body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  width: 100vw;
  height: 100vh;

  div {
    box-sizing: border-box;
  }
}

.leva-c-kWgxhW {
  bottom: 0px !important;
  top: unset !important;
  left: 50% !important;
  display: none !important;
}

.react-flow__node {
  width: auto !important;
}

// Hide the white arrow Leaflet attaches to the corner of the tooltip by default:
.leaflet-container .leaflet-tooltip {
  padding: 0;
  background-color: transparent;
  border-color: transparent;

  &::before {
    display: none;
  }
}

/*
 * We need this to show the expanded dropdowns in the Sidebar.
 * It has to go out here, not within the component-scoped CSS,
 * because the options get rendered unnested at the bottom of the <body> tag.
 */
div[role="presentation"].MuiAutocomplete-popper {
  z-index: 2000;
}

/*
 * We need this to show the expanded datepickers in the Sidebar.
 * It has to go out here, not within the component-scoped CSS,
 * because the calendars get rendered unnested at the bottom of the <body> tag.
 */
div[role="dialog"].MuiPopper-root {
  z-index: 2000;
}

