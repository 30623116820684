.container {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4a5568;
}

.select {
  border: 1px solid #d2d6dc;
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  color: #4a5568;
  outline: none;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:focus {
    border-color: #5a67d8;
    box-shadow: 0 0 0 3px rgba(90, 103, 216, 0.5);
  }
}

.error {
  border-color: #e53e3e;
}

.errorMessage {
  color: #e53e3e;
  margin-top: 0.25rem;
  font-size: 0.75rem;
}
