.toolbar {
  display: flex;
  gap: 20px;

  .createBtn {
    color: white;
    width: 300px;
    height: 100%;
  }

  .createBtn:hover {
    background-color: rgb(71, 176, 236);
  }
}
