.sections {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid gray;
  overflow-y: scroll;
  position: relative;
  height: 100%;

  .content {
    position: sticky;
    z-index: 999;
    left: -1px;
    top: 0px;
  }

  .tabsContainer {
    height: 100%;

    & > div {
      height: 100%;

      & > div {
        height: 100%;
      }
    }
  }
}
