.chart {
  width: 100%;
  height: 100%;

  .react-flow__nodes {
    display: none !important;
  }

  .react-flow__node {
    width: auto !important;
  }
}
