.event {
  width: 100%;
  display: flex;
  padding: 10px;
  gap: 10px;
  align-items: center;
  border: 1px solid;
  border-radius: 50px;

  .status {
    border-radius: 50%;
    width: 10px;
    height: 10px;
  }

  .textContainer {
    .text {
    }
  }
}
