.modal {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .modalContent {
    flex: 1;
    width: 80%;
    position: absolute;
    background-color: transparent !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .content {
      border-radius: 10px;

      .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;

        .title {
          font-size: 25px;
          font-weight: 600;
        }
      }

      .content {
        display: flex;
        width: 100%;
        margin: 0px;
        height: 700px;
        gap: 20px;
        padding: 20px;
        overflow-y: scroll;
      }

      .tabs {
        border: 1px solid black;
        margin: 10px 0px;
      }

      .selects {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
      }

      .actions {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;

        .addBtn {
          font-weight: 600;
        }

        .cancelBtn {
          margin-left: 10px;
          font-weight: 600;
          color: rgb(136, 136, 136);
        }
      }
    }
  }
}
