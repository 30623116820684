.tableComponent {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  position: relative;
  height: 100%;

  .titleContainer {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
    align-items: center;
    justify-content: flex-end;

    .title {
      font-size: 25px;
      font-weight: 600;
    }
  }

  div {
    .tagWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .tag {
        padding: 5px 8px;
        border-radius: 12px;
        font-size: 0.875rem;
        line-height: 1;

        .text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 150px;
          font-size: 12px;
        }
      }
    }
  }

  .searchContainer {
    width: 100%;
    padding: 15px 0px;
    display: flex;
    align-items: center;
    gap: 10px;

    .importBtn {
      flex: 1;
    }

    .search {
      flex: 2;
    }

    .select {
      flex: 1;
    }
  }

  .tableWrapper {
    flex: 1;
    overflow-x: hidden;
    position: relative;
    width: 100%;

    .table {
      position: absolute;
      inset: 0;
    }
  }
}
