.generalSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 500px;

  .actions {
    display: flex;
    gap: 10px;
    width: 100%;

    .saveBtn {
      flex: 3;
    }

    .deleteBtn {
      flex: 1;
    }
  }

  .sectionContainer {
    border: 1px solid gray;
    border-radius: 5px;
    width: 100%;

    .titleContainer {
      padding: 15px;
      width: 100%;
      border-bottom: 1px solid gray;

      .title {
        font-size: 18px;
        font-weight: 600;
      }
    }

    .section {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 15px;
      width: 100%;

      .checkboxContainer {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
      }

      .datePicker {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;
      }

      .fieldContainer {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .label {
          font-size: 15px;
          display: flex;

          .asterisk {
            color: red !important;
          }
        }

        .field {
          width: 300px;
        }
      }

      .releasabilityContainer {
        .field {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
}
