.modalContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 12px;
  flex: 1;

  > .formContent {
    overflow-y: auto;
    flex: 1;
  }
}

.spexPinPopup {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5000;
  width: 400px;
  height: 360px;
  background-color: white;
}
