:root {
  --background-color: #fff;
  --text-color: #000;
  --sub-container-background-color: #fff;
}

.light-mode {
  --background-color: #fff;
  --text-color: #000;
  --sub-container-background-color: #fff;
}

.dark-mode {
  --background-color: #04111a;
  --text-color: #fff;
  --sub-container-background-color: #13222e;
}

.main-container,
.sub-container {
  background-color: var(--background-color);
  color: var(--text-color);
}

.sub-container {
  background-color: var(--sub-container-background-color);
}
