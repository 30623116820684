.sidebar {
  border-right: 1px solid gray;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  overflow-x: unset;
  transition: width 1s ease;
  padding: 15px;
  width: 400px;

  .form {
    display: flex;
    flex-direction: column;

    .createBtn {
      margin: 5px 0px 10px;
      border: 1px solid white;
    }

    .select {
      margin-top: 20px;
    }
  }

  .exerciseRequests {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px;
    border: 1px solid gray;

    .exerciseRequest {
      border: 1px solid rgb(82, 82, 82);
      border-radius: 10px;
      padding: 10px;
    }

    .selectedExerciseRequest {
      border: 1px solid rgb(206, 206, 206);
      border-radius: 10px;
      padding: 10px;
    }
  }
}

.sidebar::-webkit-scrollbar {
  display: none;
}
