.toolbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .leftContainer {
    display: flex;
    justify-content: flex-start;
  }

  .rightContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    .exportContainer {
      .exportBtn {
        width: 100px;
        border: 1px solid white;
      }
    }

    .select {
      width: 200px;
    }
  }
}
