.group {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  border: 1px solid;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 20px 10px;
    gap: 10px;

    .title {
      font-size: 18px;
      font-weight: 600;
    }

    .arrow {
      transition: transform 0.3s ease;
      transform: rotate(90deg);
    }

    .bottomArrow {
      transition: transform 0.3s ease;
      transform: rotate(270deg);
    }
  }

  .events {
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 10px;
    align-items: center;
  }
}
