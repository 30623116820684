.operatingUnit {
  width: 100%;
}

.modal {
  width: 100%;
  height: 100%;

  .modalContent {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    height: auto;
    padding: 20px;
    border-radius: 10px;
    width: 900px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .content {
      .addUnit {
        border: 1px solid gray;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .btnContainer {
          width: 100%;
          margin-top: 20px;
          display: flex;
          justify-content: flex-end;

          button {
            border: 1px solid white;
          }
        }

        .fieldContainer {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .label {
            font-size: 15px;
            display: flex;

            .asterisk {
              color: red !important;
            }
          }

          .field {
            width: 350px;
          }
        }

        .btnContainer {
          width: 100%;
          margin-top: 20px;

          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;

      .addBtn {
        font-weight: 600;
        color: white;
      }

      .removeBtn {
        color: white;
      }

      .cancelBtn {
        margin-left: 10px;
        color: white;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .modal {
    .modalContent {
      width: 80%;

      .header {
        .title {
          font-size: 18px;
        }
      }
    }
  }
}
