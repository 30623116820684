.contextMenu {
  background: white;
  border-style: solid;
  box-shadow: 10px 19px 20px rgba(0, 0, 0, 10%);
  position: absolute;
  width: 100px;
  height: 50px;
  z-index: 10;
}

.contextMenu button {
  border: none;
  display: block;
  padding: 0.5em;
  text-align: left;
  width: 100%;
}

.contextMenu button:hover {
  background: white;
}
