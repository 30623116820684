.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: rgb(22, 119, 56);
  border-bottom: 1px solid gray;
  height: 75px;

  .links {
    margin-left: 20px;
    display: flex;
    gap: 20px;

    .logo {
      width: 100px;
      height: 40px;
      margin-right: 50px;
    }

    .dark {
      color: white;
      text-decoration: none;
      padding: 10px;
      font-weight: 600;
      border-radius: 10px;
    }

    .light {
      color: white;
      text-decoration: none;
      padding: 10px;
      font-weight: 600;
      border-radius: 10px;
    }

    .activeLight {
      text-decoration: none;
      padding: 10px;
      border: 1px solid white;
      color: white;
      font-weight: 600;
      border-radius: 10px;
    }

    .activeDark {
      color: white;
      text-decoration: none;
      padding: 10px;
      border: 1px solid white;
      font-weight: 600;
      border-radius: 10px;
    }
  }

  .rightSide {
    .themeModeBtn {
      margin: 0px 20px;
      width: 50px;
      height: 50px;
    }
  }
}
