.modal {
  width: 100vw;
  height: 100vh;
  position: relative;

  .modalContent {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 600px;
    height: auto;
    padding: 20px;
    border-radius: 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .content {
      border-radius: 10px;
      margin: 10px;

      .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          font-size: 25px;
          font-weight: 600;
        }
      }

      .content {
        display: flex;
        width: 100%;
        margin: 0px;
        gap: 20px;
        padding: 20px;

        .fields {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .fieldContainer {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            .field {
              width: 300px;
            }
          }
        }
      }

      .tabs {
        border: 1px solid black;
        margin: 10px 0px;
      }

      .selects {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
      }

      .actions {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;

        .addBtn {
          font-weight: 600;
        }

        .cancelBtn {
          margin-left: 10px;
          font-weight: 600;
          color: rgb(136, 136, 136);
        }
      }
    }
  }
}
